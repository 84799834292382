<template>
  <section>
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
          <v-layout wrap hidden-sm-and-down>
            <v-flex md6></v-flex>
            <v-flex md6 class="banner-right-div">
              <div class="banner-text-div">
                <p class="banner-text-en">MEMBER CENTRE</p>
                <div class="banner-text-line"></div>
                <p class="banner-text-zh">会员中心</p>
              </div>
            </v-flex>
          </v-layout>
        </v-img>
    </v-flex>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">会员中心</v-flex>
        <v-flex md6><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container">
      <div class="ctk-page-title wow fadeInUp">好睡眠VIP会员尊享特权</div>
      <div class="d-flex flex-wrap justify-space-between" flat tile >
        <v-card v-for="item in items" :key="item.key" flat tile>
            <div class="item-icon wow fadeInUp"><v-img height="98" weight="132" position="top left" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/member/icon-' + item.key + '.png'"></v-img></div>
            <div class="item-title wow fadeInUp" data-wow-delay="0.2s">{{item.title}}</div>
            <div class="img wow fadeInUp" data-wow-delay="0.4s"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/member/' + item.key + '.png'"/></div>
        </v-card>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 17,
    innerWidth: window.innerWidth,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '会员中心', disabled: true }
    ],
    items: [
      { key: 1, title: '量身定制睡眠环境功能改善解决方案' },
      { key: 2, title: '享受线上商城专属的优质福利' },
      { key: 3, title: '加入好睡眠会员专属社群，学习睡眠知识、建立睡眠好习惯、分享睡眠与健康' },
      { key: 4, title: '拥有会员之间共惠、共享、共融的平台通道和专属服务' }
    ]
  }),
  created () {
    document.title = '会员中心-好睡眠'
    document.querySelector('#menu_member').classList.add('v-btn--active')
  },
  mounted () {
    this.$wow.init()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/member.css';
</style>
